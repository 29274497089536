import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import ButtonText from "../buttons/ButtonText";
import SectionTitle from "../SectionTitle";
import GridContainer from "../GridContainer";
import { breakpoints, colors } from "../../styles/variables";
import charOne from "../../assets/images/media/mediaCharOne.png";
import charTwo from "../../assets/images/media/mediaCharTwo.png";
import charThree from "../../assets/images/media/mediaCharThree.png";

const Section = styled.section`
  position: relative;
  background-color: ${colors.purpleLight};
  padding-bottom: 11vw;

  .imageCharOne {
    position: absolute;
    width: 100%;
    max-width: 375px;
    left: 0px;
    top: -465px;
    z-index: 2;
  }
  .imageCharTwo {
    position: absolute;
    width: 100%;
    max-width: 318px;
    left: 195px;
    top: -161px;
    z-index: 2;
  }
  .imageCharThree {
    position: absolute;
    width: 100%;
    max-width: 375px;
    right: 76px;
    top: -310px;
    z-index: 2;
  }
  .gridOut {
    height: 100%;
    position: relative;
  }
  .gridIn {
    grid-column: 4 / span 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .buttonRow {
    text-align: center;
  }
  .text-header {
    margin-bottom: 32px;
  }

  @media (max-width: ${breakpoints.laptop}) {
    .imageCharOne {
      position: absolute;
      width: 100%;
      max-width: 375px;
      left: -150px;
      top: -362px;
      z-index: 2;
    }
    .imageCharTwo {
      width: 100%;
      max-width: 275px;
      position: absolute;
      left: 53px;
      top: -45px;
      z-index: 3;
    }
    .imageCharThree {
      position: absolute;
      width: 100%;
      max-width: 375px;
      right: -50px;
      top: -225px;
      z-index: 2;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .gridIn {
      grid-column: 3 / span 4;
    }

    .imageCharOne {
      display: none;
    }
    .imageCharTwo {
      display: none;
    }
    .imageCharThree {
      position: absolute;
      width: 100%;
      max-width: 225px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: -250px;
      z-index: 2;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .gridOut {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .gridIn {
      grid-column: 1 / span 2;
    }
    .imageCharThree {
      position: absolute;
      width: 100%;
      max-width: 200px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: -180px;
      z-index: 2;
    }
  }
  @media (max-width: 600px) {
    .imageSwirl {
      max-width: 150px;
      bottom: -75px;
      right: -40px;
    }
    .imageChar {
      top: 17%;
    }
  }
`;

const SVGLightningLines = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  top: -22vw;
  width: 100%;
  height: auto;
  z-index: 1;
  opacity: 0.3;
`;

const data = {
  title: "Media Inquiries",
  description:
    "In quis mi rhoncus, euismod neque non, tristique nisl. Maecenas porta dui in justo cursus, lacinia tincidunt odio auctor. Fusce quam velit, finibus at lorem vitae, ornare tempor nulla. ",
  button_text: "Contact us",
  click_url: "https://www.google.com",
};

export const fragment = graphql`
  fragment MediaContactFragment on Contentstack_starblitz_media_page {
    mediaContact: media_page_contact_section {
      alignment
      headline
      bodyCopy: body_copy
      ctaLink: cta_link {
        title
        href
      }
    }
  }
`;

export interface MediaInquiriesData {
  alignment: string;
  headline: string;
  bodyCopy: string;
  ctaLink: {
    title: string;
    href: string;
  };
}

interface Props {
  data: MediaInquiriesData;
}

const MediaInquiries = (props: Props) => {
  const { headline, bodyCopy, ctaLink } = props.data;

  return (
    <Section>
      <img className="imageCharOne" src={charOne} />
      <img className="imageCharTwo" src={charTwo} />
      <img className="imageCharThree" src={charThree} />
      <GridContainer className="gridOut">
        <div className="gridIn">
          <SectionTitle colorTheme="white" title={headline} hideImageDivider={true}>
            {bodyCopy}
          </SectionTitle>
          <div className="buttonRow">
            <ButtonText
              colorTheme="border-black"
              callback={() => {
                window.open(ctaLink.href, "_new");
              }}
            >
              {ctaLink.title}
            </ButtonText>
          </div>
        </div>
      </GridContainer>
      <SVGLightningLines
        fill="none"
        height="398"
        viewBox="0 0 1440 398"
        width="1440"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m2453.8 396.6-1622.8-236.6 16.5 90-1620.5-241" stroke="#E5C77F" />
        <path d="m2456.8 389.6-1619.501-236.6 16.5 90-1627.299-242" stroke="#E5C77F" />
      </SVGLightningLines>
    </Section>
  );
};

export default MediaInquiries;
