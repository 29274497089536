import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import GridContainer from "../GridContainer";
import VideoPreview from "../VideoPreview";
import { breakpoints, colors, zIndices } from "../../styles/variables";

import imgBackground from "../../assets/images/backgroundMedia.jpg";
import flagOne from "../../assets/images/media/mediaFlagOne.png";
import flagTwo from "../../assets/images/media/mediaFlagTwo.png";

export const fragment = graphql`
  fragment MediaMarqueeFragment on Contentstack_starblitz_media_page {
    mediaMarquee: media_marquee_section {
      headline
      flavorCopy: flavor_copy
      descriptionCopy: description_copy
      legalCopy: legal_copy
      marqueeCta: marquee_cta {
        title
        href
      }
      youtubeEmbedLink: youtube_embed_link {
        title
        href
      }
      videoThumb: video_thumb {
        url
      }
      inlineVideoFile: inline_video_file {
        url
      }
      videoDescriptor: video_descriptor
    }
  }
`;

export interface MediaHeroData {
  headline: string;
  flavorCopy: string;
  descriptionCopy: string;
  legalCopy: string;
  marqueeCta: {
    title: string;
    href: string;
  };
  youtubeEmbedLink: {
    title: string;
    href: string;
  };
  videoThumb: {
    url: string;
  };
  inlineVideoFile: {
    url: string;
  };
  videoDescriptor: string;
}

interface Props {
  data: MediaHeroData;
  videoOpen: Function;
}

interface Props {
  videoOpen: Function;
}

export default function MediaHero(props: Props) {
  const {
    headline: title,
    flavorCopy: subTitle,
    descriptionCopy: description,
    legalCopy: cta_subtext,
    marqueeCta: { title: cta_text, href: cta_href },
    videoThumb: { url: thumbImageURL },
    inlineVideoFile: { url: thumbVideoURL },
    videoDescriptor: videoText,
  } = props.data;

  // TODO: Must link to full ZIP
  const downloadAll = () => {
    window.open(cta_href, "_new");
  };

  return (
    <Section className="lazyload" style={{ backgroundImage: `url("${imgBackground}")` }}>
      <GridContainer className="gridOut">
        <div className="text-header">{title}</div>
        <div className="text-miniheader">{subTitle}</div>
        <div className="videoBox">
          <VideoPreview
            videoOpen={props.videoOpen}
            thumbnailImage={thumbImageURL}
            videoURL={thumbVideoURL}
            textCaption={videoText}
            className="videoCustom"
          ></VideoPreview>
          {/* Time squares near video */}
          <img src={flagOne} className="flagOne" />
        </div>
        <div className="bigParagraph">
          <div className="text-paragraph" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </GridContainer>
      <SVGLightningLines
        width="1440"
        height="337"
        viewBox="0 0 1440 337"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M-574.5 336.5L685.799 151L669.299 241L2236.8 8" stroke="#E5C77F" />
        <path d="M-575 328L679.5 144L663 234L2230.5 1" stroke="#E5C77F" />
      </SVGLightningLines>
    </Section>
  );
}

const Section = styled.section`
  background-position: center -100px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 14.5vw;
  margin-bottom: -11.5vw;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(53, 61, 111, 0.8);
  }

  .gridOut {
    padding-top: 100px;
  }

  a {
    color: ${colors.white};
  }

  .text-header {
    grid-column: 1 / span 6;
    color: ${colors.white};
    text-align: left;
  }
  .text-miniheader {
    grid-row: 2 / span 1;
    grid-column: 1 / span 6;
    margin-top: 0.5em;
    color: ${colors.white};
    text-align: left;
    text-transform: uppercase;
  }
  .downloadBtn {
    margin: 32px 0;
  }

  .videoBox {
    position: relative;
    grid-column: 8 / span 5;
    grid-row: 1 / span 3;
  }

  .videoCustom {
    width: 100%;
    max-width: 100%;
    z-index: 2;
  }

  /* time squares */

  .flagOne {
    position: absolute;
    width: 290px;
    top: 103%;
    right: 7%;
  }

  .flagTwo {
    position: absolute;
    width: 246px;
    right: -12%;
    top: 66%;
    z-index: 2;
  }

  .bigParagraph {
    grid-column: 2 / span 5;
    grid-row: 3 / span 3;
    margin-top: 64px;
    color: ${colors.white};
  }

  @media (max-width: ${breakpoints.laptop}) {
    background-size: auto;
    background-position: -450px -120px;

    .solidSquare {
      top: 70%;
    }

    .semiSolidSquare {
      top: 110%;
      right: 0;
    }

    .lessSolidSquare {
      top: 142%;
      right: 32%;
    }

    .bigParagraph {
      grid-column: 2 / span 5;
      grid-row: 3 / span 3;
      margin-top: 64px;
      color: ${colors.white};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .gridOut {
      padding-top: 50px;
    }

    .videoBox {
      grid-column: 1 / span 8;
      grid-row: initial;
      max-width: 100%;
      margin: 32px 0;
    }

    .videoCustom {
      width: 100%;
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
    }

    .bigParagraph {
      margin-top: 0;
      grid-row: initial;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-bottom: 48px;
    }

    .text-paragraph {
      grid-column: 1 / span 2;
      width: 85%;
    }

    .solidSquare {
      top: -30%;
      right: 15%;
      left: auto;
    }

    .semiSolidSquare {
      top: -50%;
      right: -20%;
    }

    .lessSolidSquare {
      top: 40%;
      right: -10%;
    }

    .text-header,
    .text-miniheader,
    .videoBox,
    .bigParagraph {
      grid-column: 2 / span 6;
    }

    .text-header,
    .text-miniheader {
      margin-bottom: 14px;
    }

    .downloadBtn {
      margin: 0;
      height: fit-content;
      width: fit-content;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    .gridOut {
      padding-top: 24px;
    }
    .text-header,
    .text-miniheader,
    .videoBox,
    .bigParagraph {
      grid-column: 1 / span 2;
    }

    .solidSquare,
    .semiSolidSquare,
    .lessSolidSquare {
      display: none;
    }

    .bigParagraph {
      grid-template-columns: 1fr;
    }

    .text-paragraph {
      grid-column: 1fr;
      width: 100%;
    }

    .downloadBtn {
      margin: 32px auto;
    }
  }
`;

const SVGLightningLines = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: -2vw;
  width: 100%;
  height: auto;
  z-index: ${zIndices.lightningClip};
  opacity: 0.3;
`;
